import { GetStaticProps } from "next";
import { FunctionComponent } from "react";
import { SuperHero } from "@bluebottlecoffee/design-system/components";
import sanityClient from "../lib/sanity-client";
import { oneClickSubscribeCardDelanguagingQuery } from "../lib/sanity/one-click-subscribe-module-queries";
import renderModule from "../lib/render-module";
import { toSuperHeroProps } from "../lib/transformers/super-hero";
import { MiniCartDialog } from "../components/MiniCartDialog";
import { LayoutWrapper } from "../components/LayoutWrapper";
import { getDefaultStaticPropsMiniCartWithSlug } from "../lib/utils";
import {
  CafeGroup,
  CafeGroupFeature,
  ErrorStatus,
  ShippingCopy,
} from "../lib/sanity-schema";
import { CartBasePropsWithSlug } from "../lib/sanity/shared";
import { filters, keyof } from "../lib/sanity/utils";
import localization from "../localization";
import { REGION } from "../lib/i18n";
import { isFeatureEnabled } from "../lib/utils/is-feature-enabled";
import { quickShopCarouselCardQuery } from "../lib/sanity/quick-shop-carousel-queries";
import { shippingCopyQuery } from "../lib/sanity/product-queries";
import {
  TranslationGroupSanityType,
  buildTranslationGroupQuery,
  toCopyObject,
} from "../lib/sanity/translation-group-queries";
import { ConversionCopy } from "./[region]/[lang]/product/[slug]";

interface PageData extends CartBasePropsWithSlug {
  pageProps: ErrorStatus;
  shopcardCopy: {
    copy: ConversionCopy;
    shippingCopy: ShippingCopy;
  };
}

export const getStaticProps: GetStaticProps<PageData> = async () => {
  const lang = localization.defaultLanguage(REGION).code;

  const { props: defaultProps } = await getDefaultStaticPropsMiniCartWithSlug({
    params: {
      region: REGION,
      slug: "404",
      lang,
    },
  });

  const pageProps = await sanityClient().fetch<ErrorStatus>(`*[
      ${filters.whereType("errorStatus")}
      && ${keyof<ErrorStatus>("code")} == "404"
      && ${filters.excludeDrafts}
    ]{
      ...,
      ${keyof<ErrorStatus>("modules")}[]{
        ...,
        ${filters.whereType("cafeGroupFeature")} => {
          ${keyof<CafeGroupFeature>("cafeGroups")}[]{
            ...,
            ${keyof<CafeGroup>("cafes")}[]->
          }
        },
        ${quickShopCarouselCardQuery(lang)},
        ${oneClickSubscribeCardDelanguagingQuery()}
      }
    }[0]`);

  const conversionCopy: ConversionCopy = await sanityClient()
    .fetch<TranslationGroupSanityType>(
      buildTranslationGroupQuery(lang, "Conversion"),
    )
    .then((translations: TranslationGroupSanityType) =>
      toCopyObject<ConversionCopy>(lang, translations),
    );

  const shippingCopy: ShippingCopy =
    await sanityClient().fetch<ShippingCopy>(shippingCopyQuery);

  return {
    props: {
      pageProps,
      shopcardCopy: {
        copy: conversionCopy,
        shippingCopy,
      },
      ...defaultProps,
    },
  };
};

const Custom404: FunctionComponent<PageData> = ({
  pageProps,
  shopcardCopy,
  ...defaultProps
}) => {
  const { appPlayLinks, flavorProfileCopy, ...layoutWrapperProps } =
    defaultProps;
  const {
    region,
    lang,
    giftCardFormCopy,
    subscribableProducts,
    cart,
    productRecs,
    navAndCartInfoBanner,
    aria,
  } = layoutWrapperProps;
  const { title, hero, modules } = pageProps || {};

  const miniCartDialog = (
    <MiniCartDialog
      productRecs={productRecs}
      giftCardFormCopy={giftCardFormCopy}
      miniCartCopy={cart}
      navAndCartInfoBanner={navAndCartInfoBanner}
      region={region}
      lang={lang}
      subscribableProducts={
        isFeatureEnabled(
          process.env.NEXT_PUBLIC_CART_SUBSCRIPTION_TOGGLE_ENABLED,
        )
          ? subscribableProducts
          : []
      }
    />
  );

  return (
    <LayoutWrapper
      {...{
        ...layoutWrapperProps,
        pageTitle: title?.[lang] || "404",
        slideOutContent: [miniCartDialog],
        openMiniCartPanel: Boolean(miniCartDialog),
      }}
      isTransparent
    >
      {hero?.length > 0 && (
        <SuperHero {...toSuperHeroProps(hero, region, lang)} />
      )}
      {modules?.length > 0 &&
        modules.map((module) =>
          renderModule({
            module,
            region,
            lang,
            appPlayLinks,
            flavorProfileCopy,
            shopcardCopy,
            aria,
          }),
        )}
    </LayoutWrapper>
  );
};

export default Custom404;
